
function Footer () {
    
    return (
        <div className="footer">
            <h1 >Thousands of Datafiends can help scrape anything. </h1>
        </div>
    );
  }

export default Footer;